import * as React from "react"
import { Link } from "gatsby"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Avatar, Container, Grid, withWidth } from "@material-ui/core";

interface Profile {
    id?: string;
    name: string;
    role: string;
    company: string;
    icon?: string
}

interface Props {
    highlighted: string;
    profile: Profile;
    text: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: 'white',
        },
        wrapper: {
            padding: '50px 0',
            [theme.breakpoints.up('md')]: {
                padding: 230,
            },
        },
        quotationSymbol: {
            fontSize: '110px',
            lineHeight: '70px',
            color: theme.palette.primary.main,
        },
        quote: {
            fontSize: 32,
            fontWeight: 300,
            lineHeight: 1.47,
            [theme.breakpoints.down('sm')]: {
                fontSize: 22,
            },
        },
        highlighted: {
            fontWeight: 600,
        },
        profile: {
            display: 'flex',
            alignItems: 'center',
            fontSize: 16,
            fontWeight: 300,
            lineHeight: 1.26,
            paddingTop: 47,
        },
        profileText: {
            marginLeft: 15,
            maxWidth: 275,
            fontSize: 15,
        },
        avatarImage: {
            maxHeight: "40px",
        },
        avatarImageContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
    }),
);

const Quotation: React.FC<Props> = ({ text, highlighted, profile }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container maxWidth="lg">
                <div className={classes.wrapper}>
                    <Grid container spacing={2}>
                        <Grid item xs={1}>
                            <div className={classes.quotationSymbol}>
                                “
                            </div>
                        </Grid>
                        <Grid item xs={11}>
                            <div className={classes.quote}>
                                {text}
                                <span className={classes.highlighted}>{highlighted}</span>
                            </div>
                            <div className={classes.profile}>
                                {profile.icon ? <div className={classes.avatarImageContainer}><img src={profile.icon} className={classes.avatarImage} /></div> : <Avatar>{profile.name.charAt(0)}</Avatar>}
                                <div className={classes.profileText}>
                                    <span className={classes.highlighted}>{profile.name}</span>, {profile.role}<br/>
                                    <span className={classes.highlighted}>{profile.company}</span>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </div>
    )
}

export default Quotation
