import * as React from "react"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Divider, Paper, Typography } from "@material-ui/core";
import Checkmark from "../Checkmark";
import ReactMarkdown from "react-markdown";
import { Link } from "gatsby";

interface ListItem {
    id: string;
    text: string;
}
interface Props {
    gatsbyImage?: IGatsbyImageData;
    publicURL?: string;
    title: string;
    button?: {
        text: string;
        link: string;
        isHighlighted: boolean;
    };
    items?: ListItem[] | {
        list: ListItem[]
    }[]
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: 4,
            padding: "0 16px",
            height: "100%",
        },
        paperIcon: {
            height: "30px",
            margin: "16px 16px 0 16px",
        },
        paperTitle: {
            margin: "32px",
        },
        paperItem: {
            padding: "16px",
            width: "100%",
        },
        secondToLastItem: {
            flexGrow: 1,
        },
        divider: {
            color: "#8690a0",
            margin: '60px 0 24px 0',
            height: '1px',
            width: "85%",
        },
        textHighlighted: {
            fontSize: "17px",
            fontWeight: "bold",
            textAlign: "center",
        },
        link: {
            textDecoration: "none",
        },
    }),
);

const Tile: React.FC<Props> = ({ items, publicURL, title, button }) => {
    const classes = useStyles();

    return <Paper className={classes.paper}>
        <img className={classes.paperIcon} src={publicURL} />
        <Typography variant="h4" className={classes.paperTitle}>
            {title}
        </Typography>
        <Link to={button.link} className={classes.link}>
            <Button size="large" variant="contained" color={button.isHighlighted ? "primary" : "default"}>{button.text}</Button>
        </Link>
        <Divider light className={classes.divider} />
        {items.map((item, index) => <React.Fragment>
            {item.list.length !== 0
                ? <div className={[classes.paperItem, items.length - 2 === index && classes.secondToLastItem].join(" ")}>{item.list.map(listItem => <Checkmark uncircled={true} key={listItem.id} text={listItem.text} typography="body1" />)}</div>
                : <div className={[classes.paperItem, items.length - 2 === index && classes.secondToLastItem].join(" ")}><Typography variant="body1" gutterBottom className={item.isTextHighlighted ? classes.textHighlighted : null}>
                    <ReactMarkdown linkTarget="_blank">{item.text}</ReactMarkdown>
                </Typography></div>
            }
        </React.Fragment>)}
    </Paper>
}

export default Tile
