import * as React from "react"
import { graphql, PageProps } from "gatsby"
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import Layout from "../components/Layout"
import Quotation from "../components/Quotation";
import Section from "../components/Section";
import Seo from "../components/seo";
import { FooterProps } from "../components/Footer";
import { NavigationProps } from "../components/Header/navigation";
import Headline from "../components/Headline";
import { Grid, Typography } from "@material-ui/core";
import Tile from "../components/Tile";
import CustomTable from "../components/CustomTable";
import StyledAccordion from "../components/StyledAccordion";
import ReactMarkdown from "react-markdown"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        questionsTitle: {
            margin: "50px",
        },
        desktop: {
            display: "none",
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
        },
        mobile: {
            display: "flex",
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
    }),
);

type DataProps = {
    strapiPriceList: any
    strapiNavigation: NavigationProps
    strapiFooter: FooterProps
}

const PriceList: React.FC<PageProps<DataProps>> = ({ data }) => {
    const classes = useStyles();
    const navigation = data.strapiNavigation;
    const footer = data.strapiFooter;
    const priceList = data.strapiPriceList;
    const quotation = data.strapiPriceList.quotation;
    const headline = data.strapiPriceList.headline;
    const table = data.strapiPriceList.table;
    const questions = data.strapiPriceList.questions.question;
    const questionsTitle = data.strapiPriceList.questionsTitle;
    const tiles = data.strapiPriceList.tile;
    const seo = data.strapiPriceList.seo;

    return (
        <Layout navigation={navigation} footer={footer}>
            <Seo
                title={`${seo && seo[0] && seo[0].metaTitle || priceList.title}`}
                description={
                    seo && seo[0] && seo[0].metaTitle
                        ? seo[0].metaTitle
                        : <>
                            <ReactMarkdown linkTarget="_blank" >{priceList.topRichText}</ReactMarkdown>
                            <ReactMarkdown linkTarget="_blank" >{priceList.bottomRichText}</ReactMarkdown>
                        </>
                }
                imageURL={seo && seo[0] && seo[0].shareImage && seo[0].shareImage.localFile && seo[0].shareImage.localFile.publicURL}
            />
            <Section color="white">
                <Headline
                    titleBefore={headline.titleBefore}
                    highlighted={headline.highlighted}
                    titleAfter={headline.titleAfter}
                    text={headline.text}
                    inputLabel={headline.inputLabel}
                    inputButtonText={headline.inputButtonText}
                    badge={headline.badge}
                    mainLink={headline.mainLink && headline.mainLink}
                />
                <Grid container spacing={3}>
                    {tiles.map((tile, i) => <React.Fragment>
                        {i % 2 === 0 && <Grid item sm={false} md={2} className={classes.desktop} />}
                        <Grid item sm={12} md={4}>
                            <Tile
                                title={tile.title}
                                publicURL={tile.image.localFile.publicURL}
                                button={tile.button}
                                items={tile.row}
                            />
                        </Grid>
                        {i % 2 !== 0 && <Grid item sm={false} md={2} className={classes.desktop} />}
                    </React.Fragment>

                    )}
                </Grid>
                {table.map(table => <CustomTable
                    title={table.title}
                    columns={table.Columns.column.map(header => header.text)}
                    rows={table.Rows.Row}
                    anchor={priceList.tableAnchor}
                />)}
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {
                            questionsTitle && <Typography variant="h3" align="center" className={classes.questionsTitle}>
                                {questionsTitle}
                            </Typography>
                        }
                    </Grid>
                </Grid>

                {questions && <StyledAccordion items={questions} />}
                <Quotation
                    text={quotation.text}
                    highlighted={quotation.highlighted}
                    profile={{
                        ...quotation.profile,
                        icon: quotation.profile.icon && quotation.profile.icon.localFile.publicURL
                    }}
                />
            </Section>
        </Layout >
    );
}

export default PriceList


export const query = graphql`
query PricelistPage($locale: String) {
    strapiPriceList(locale: {eq: $locale}) {
        tableAnchor
        seo {
            metaTitle
            metaDescription
            shareImage {
                localFile {
                    publicURL
                }
            }
        }
        title
        topRichText
        bottomRichText
        id
        list {
            items {
                id
                text
                }
        }
        quotation {
            highlighted
            text
            profile {
                company
                id
                name
                role
                icon {
                    localFile {
                        publicURL
                    }
                }
            }
        }
        table {
            title
            Columns {
            column {
                isPlaceholder
                text
                id
            }
            }
            Rows {
            id
            Row {
                id
                column {
                    highlighted
                    isTextHighlighted
                    isCheckmark
                    isCross
                    isPlaceholder
                    # list {
                    #     text
                    #     id
                    # }
                    text
                    id
                    link
                }
            }
            }
        }
        headline {
            highlighted
            titleBefore
            text
        }
        questionsTitle
        questions {
            question {
            description
            answer
            id
            title
            }
        }
        tile {
            button {
                id
                isHighlighted
                text
                link
            }
            id
            image {
                localFile {
                    publicURL
                }
            }
            row {
                id
                isTextHighlighted
                text
                list {
                    text
                    id
                }
            }
            title
        },
    }
    strapiNavigation(locale: {eq: $locale}) {
        locale
        navigationItem {
            title
            customLink
            NavigationSubItems {
                title
                sub_page {
                    slug
                    pageTitle
                }
                slug
            }
        }
        ContactUsButton {
            link
            text
        }
    }
  strapiFooter(locale: {eq: $locale}) {
      bottomLinks {
        id
        link
        text
      }
        locale
        columns {
            FooterColumnItem {
                text
                link
                anchor
            }
            Title
        }
        contactsSectionLinks {
            link
            text
        }
        city
        state
        zipCode
        email
        id
        identification
        mobilePhone
        street
        socialMedia {
            name
            image {
                localFile {
                    publicURL
                }
            }
            link
        }
        bottomText
        bottomHeadlineSection {
            mainLink {
                link
                text
            }
            inputButtonText
            highlighted
            inputLabel
            titleAfter
            titleBefore
        }
    }
}
`;
