import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, createStyles, makeStyles, Theme, Typography, Grid } from '@material-ui/core';
import * as React from 'react';
import Checkmark from '../Checkmark';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import { Link } from 'gatsby';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tableContainer: {
            padding: "25px 50px 50px 50px",
            [theme.breakpoints.down('md')]: {
                padding: "25px 16px",
            },
        },
        headerCell: {
            padding: "20px 16px",
            border: 0
        },
        tableCell: {
            padding: "21px",
            border: 0
        },
        accentedCell: {
            color: "#fff",
            backgroundColor: "#ea1674",
            fontSize: "16px",
            lineHeight: "14px",
            fontWeight: 600,
        },
        tableHeadline: {
            marginTop: "50px",
        },
        accentedRow: {
            background: "#f5f9f9",
        },
        isTextHighlighted: {
            fontWeight: "bold",
            fontSize: "16px",
        },
        link: {
            textDecoration: "none",
            color: "inherit",
        },
    }),
);

type TableCellProps = {
    highlighted: boolean
    id: any
    isCheckmark: boolean
    isCross: boolean
    isPlaceholder: boolean
    isTextHighlighted: boolean
    list: {
        id: any
        text: string
    }[]
    text: string
    link: string
}

interface Props {
    title?: string
    columns: React.ReactChildren[] | string[]
    rows: {
        id: any
        column: TableCellProps[]
    }[]
    anchor?: string
}

const CustomTable: React.FunctionComponent<Props> = ({title, columns, rows, anchor}) => {
    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                {
                    title && <Typography variant="h3" align="center" className={classes.tableHeadline} id={anchor}>
                        {title}
                    </Typography>
                }
            </Grid>
            <Grid item xs="auto" md={1} />
            
            <Grid item xs={12} md={10}>
                <TableContainer className={classes.tableContainer}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {columns.map(column => <TableCell key={column} align="center" className={classes.headerCell}>
                                    <Typography variant="h4">{column}</Typography>
                                </TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row, i) => (
                            <TableRow
                                key={row.id}
                                className={i % 2 === 0 ? classes.accentedRow : null}
                                >
                                {row.column.map(column => <TableCell
                                    key={row.id + column.id}
                                    align="center"
                                    style={i === rows.length -1  ? { border: 0 } : {}}
                                    className={[
                                        classes.tableCell,
                                        column.highlighted && classes.accentedCell,
                                        column.isTextHighlighted && classes.isTextHighlighted,
                                    ].join(' ')}
                                    >
                                        <Link to={column.link} className={classes.link}>
                                            {
                                                column.list && column.list.length !== 0
                                                    ? column.list.map(item => <Checkmark text={item.text}  />)
                                                    : column.isCheckmark
                                                        ? <CheckIcon color="primary" />
                                                        : column.isCross
                                                            ? <CloseIcon />
                                                            : column.text
                                            }
                                        </Link>
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
            <Grid item xs="auto" md={2} />
        </Grid>
    );
}
export default CustomTable